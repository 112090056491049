import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

import APIService from '../services/API';
import { useUser } from '../store/hooks/hooks';

import './NotificationBar.scss';

function NotificationBar(props: RouteComponentProps) {
  const [owners, setOwners] = useState<any[]>([]);
  const user = useUser();

  const getInstantOwners = async () => {
    const result = await APIService.fetchV2InstantOwners(false);
    setOwners(result.data);
  };

  const handleRegionClick = (regions: string[]) => {
    const regionParams = regions.map((item: string) => 'region=' + item);
    props.history.push(`/don-hang-nhanh?${regionParams.join('&')}`);
  };

  useEffect(() => {
    if (user.isLoggedIn()) {
      getInstantOwners();
    }
  }, [user]);

  return user.isLoggedIn() ? (
    <div className="notification-wrapper">
      <Marquee className="notification-bar">
        {owners.map((owner: any, index: number) => {
          const handleNotificationClick = () => {
            props.history.push(`/don-hang-nhanh?ownerId=${owner.owner_id}`);
          };
          return (
            <span
              onClick={handleNotificationClick}
              key={Math.random()}
              className={classnames('notification-item', { odd: index % 2 === 0 })}
            >
              {owner.owner_id} {owner.owner_from} - {owner.owner_to}
            </span>
          );
        })}
      </Marquee>
      <div className="notification-regions">
        <div className="region-items north">
          <div className="region-title" onClick={() => handleRegionClick(['B1', 'B2'])}>
            MIỀN BẮC
          </div>
          <div className="region-child">
            <div onClick={() => handleRegionClick(['B1'])}>B1</div>
            <div onClick={() => handleRegionClick(['B2'])}>B2</div>
          </div>
        </div>
        <div className="region-items middle">
          <div className="region-title" onClick={() => handleRegionClick(['T1', 'T2', 'T3'])}>
            MIỀN TRUNG
          </div>
          <div className="region-child">
            <div onClick={() => handleRegionClick(['T1'])}>T1</div>
            <div onClick={() => handleRegionClick(['T2'])}>T2</div>
            <div onClick={() => handleRegionClick(['T3'])}>T3</div>
          </div>
        </div>
        <div className="region-items south">
          <div className="region-title" onClick={() => handleRegionClick(['N1'])}>
            MIỀN NAM
          </div>
          <div className="region-child">
            <div onClick={() => handleRegionClick(['N1'])}>N1</div>
          </div>
        </div>
        <div className="region-items west-south">
          <div className="region-title" onClick={() => handleRegionClick(['TN1'])}>
            TÂY NGUYÊN
          </div>
          <div className="region-child">
            <div onClick={() => handleRegionClick(['TN1'])}>TN1</div>
          </div>
        </div>
        <div className="region-items west">
          <div className="region-title" onClick={() => handleRegionClick(['MT1', 'MT2'])}>
            MIỀN TÂY
          </div>
          <div className="region-child">
            <div onClick={() => handleRegionClick(['MT1'])}>MT1</div>
            <div onClick={() => handleRegionClick(['MT2'])}>MT2</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default withRouter(NotificationBar);

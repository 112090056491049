import { Middleware, MiddlewareAPI } from 'redux';

import { AppActions, AppDispatch, AppState } from '..';
import { getSocketConnection } from '../../services/Socket';
import { SOCKET_IO_CONNECT, SOCKET_IO_DISCONNECT } from './types';
import {
  setInstants,
  setNewChatMessage,
  setUpdateChatMessage,
  setUnreadMessageCount,
  setInstantSuggestions,
  setOwnerAssignments,
  setNewOrder,
  setInstantOwnerID,
  setNewPriceQuotes,
  setV2Instants,
  setInstantReserve,
} from '../user/actions';
// import AudioMessage from '../../statics/sounds/new-mess.mp3';
import CONSTANTS from '../../constants/constant';
// import { getUserStorage } from '../user/utils';

export function createSocketMiddleware() {
  const middleware: Middleware = (store: MiddlewareAPI<AppDispatch, AppState>) => {
    return (next) => (action: AppActions) => {
      switch (action.type) {
        case SOCKET_IO_CONNECT:
          registerSocketEvents(store.dispatch, getSocketConnection());
          return;
        case SOCKET_IO_DISCONNECT:
          getSocketConnection().close(); // TODO of registered events also ???
          return;
        default:
          break;
      }
      return next(action);
    };
  };

  return middleware;
}

function registerSocketEvents(dispatch: AppDispatch, socket: any) {
  // const user = getUserStorage();
  socket.removeAllListeners();

  socket.on(CONSTANTS.SOCKET_EVENT.MESSAGE_COUNT, (data: number) => {
    if (data > 0) {
      // const audioNotification = new Audio(AudioMessage);
      // audioNotification.play().then((r) => {});
    }
    dispatch(setUnreadMessageCount(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_MESSAGE, (data: any) => {
    if (data) {
      const newMsg = JSON.parse(data);
      if (
        newMsg.type !== 'system-adv' &&
        newMsg.type !== 'system' &&
        newMsg.type !== 'system-time'
        //  ||
        // Number(newMsg.message?.user_id) !== user.id
      ) {
        // const audioNotification = new Audio(AudioMessage);
        // audioNotification.play().then((r) => {});
      }
    }
    dispatch(setNewChatMessage(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.UPDATE_MESSAGE, (data: any) => {
    dispatch(setUpdateChatMessage(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_INSTANT, (data: any) => {
    dispatch(setInstants(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_INSTANT_V2, (data: any) => {
    dispatch(setV2Instants(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_INSTANT_OWNER, (data: any) => {
    dispatch(setInstantOwnerID(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_ORDER, (data: any) => {
    dispatch(setNewOrder(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_PRICE_QUOTE, (data: any) => {
    dispatch(setNewPriceQuotes(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.INSTANT_RESERVE, (data: any) => {
    dispatch(setInstantReserve(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_OWNER_ASSIGNMENT, (data: any) => {
    dispatch(setOwnerAssignments(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_MATCHING_OWNER, (data: any) => {
    // dispatch(setMatchingOwner(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_MATCHING_ORDER, (data: any) => {
    // dispatch(setMatchingOrder(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.UPDATE_SUGGESTION, (data: any) => {
    dispatch(setInstantSuggestions(data));
  });

  socket.on('error', () => {
    window.location.reload();
  });

  socket.on('disconnect', () => {
    if (process.env.NODE_ENV !== 'development') {
      // window.location.reload();
    }
  });
}

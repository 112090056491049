import axios from 'axios';
import { config } from '../config';

const APIs = {
  DRIVERS: '/drivers',
  ORDERS: '/orders',
  CUSTOMERS: '/customers',
  OWNERS: '/owners',
  INSTANTS: '/instants',
  TRANSPORTS: '/transports',
  CHANHS: '/chanhs',
  CHANH_DHS: '/chanhdhs',
  WORKING_TIME: '/working',
  CONTACTS: '/contacts',
  INSURANCES: '/insurances',
  SETTINGS: '/settings',
  INSURANCE_HISTORIES: '/insurance-histories',
  OWNER_INSURANCES: '/owner-ins',
  WEB_CHAT: '/web-chat',
  REGULATIONS: '/regulations',
  REGULATION_VIOLATIONS: '/regulation-violations',
  BUTTONS: '/buttons',
  FILES: '/files',
  FINANCIAL: '/financial',
  OWNER_ASSIGNMENTS: '/owner-assignments',
  PRICE_QUOTES: '/price-quotes',
  MANAGER_SETTINGS: '/manager-settings',
  PAYMENTS: '/payments',
};

async function fetchDrivers(info = false) {
  const { data } = await axios.get(APIs.DRIVERS, {
    params: {
      info,
    },
  });
  return data;
}

async function fetchDriversPagination(params: any) {
  const { data } = await axios.get(`${APIs.DRIVERS}/pagination`, {
    params,
  });
  return data;
}

async function fetchDriverByDriverId(driverId: string, info = false) {
  const { data } = await axios.get(APIs.DRIVERS, {
    params: {
      driver_id: driverId,
      info,
    },
  });
  return data;
}

async function updateNearingExpirationDay(values: any) {
  const { data } = await axios.put(`${APIs.SETTINGS}`, values);
  return data;
}

async function updateTakeCareClient(values: any) {
  const { data } = await axios.post(`${APIs.INSURANCE_HISTORIES}`, values);
  return data;
}

async function fetchInsuranceHistory(insurance_id: number) {
  const { data } = await axios.get(`${APIs.INSURANCE_HISTORIES}`, {
    params: {
      insurance_id,
    },
  });
  return data;
}

async function addDriver(values: any) {
  const { data } = await axios.post(APIs.DRIVERS, values);
  return data;
}

async function updateDriver(values: any) {
  const { data } = await axios.put(APIs.DRIVERS, values);
  return data;
}

async function updateDriverInteractive(values: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/interactive`, values);
  return data;
}

async function fetchVehiclesByDriverID(driverID: any) {
  const { data } = await axios.get(`${APIs.DRIVERS}/vehicle`, {
    params: {
      driverID,
    },
  });
  return data;
}

async function addVehicle(values: any) {
  const { data } = await axios.post(`${APIs.DRIVERS}/vehicle`, values);
  return data;
}

async function updateVehicle(values: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/vehicle`, values);
  return data;
}

async function addLicensePlate(values: any) {
  const { data } = await axios.post(`${APIs.DRIVERS}/license-plate`, values);
  return data;
}

async function updateLicensePlate(values: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/license-plate`, values);
  return data;
}

async function removeDriver(id: number) {
  const { data } = await axios.delete(`${APIs.DRIVERS}/${id}`);
  return data;
}

async function fetchDriverAssign() {
  const { data } = await axios.get(`${APIs.DRIVERS}/driver-assign`);
  return data;
}

async function assignDriverToUser(userId: number, ids: number[]) {
  const { data } = await axios.put(`${APIs.DRIVERS}/driver-assign`, {
    userId,
    ids,
  });
  return data;
}

async function autoAssignDrivers(userIds: number[]) {
  const { data } = await axios.post(`${APIs.DRIVERS}/auto-assign`, {
    userIds,
  });
  return data;
}

async function changeDriverAssignSupporter(assignmentID: number, supporter: string) {
  const { data } = await axios.put(`${APIs.DRIVERS}/driver-assign/supporter`, {
    id: assignmentID,
    supporter,
  });
  return data;
}

async function moveDriverAssign(payload: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/driver-assign/move`, payload);
  return data;
}

async function removeUserFromDriverAssign(id: number) {
  const { data } = await axios.delete(`${APIs.DRIVERS}/driver-assign/${id}`);
  return data;
}

async function fetchChatManagers(chatType: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/chat-managers`, {
    params: {
      chatType,
    },
  });
  return data;
}

async function updateChatManagers(payload: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/chat-managers`, payload);
  return data;
}

async function findDriverByDriverID(phone: string) {
  const { data } = await axios.get(`${APIs.DRIVERS}/find`, {
    params: { phone: phone },
  });
  return data;
}

async function addDriverBankInfo(values: any) {
  const { data } = await axios.post(`${APIs.DRIVERS}/bank-info`, values);
  return data;
}

async function updateDriverBankInfo(values: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/bank-info`, values);
  return data;
}

async function removeDriverBankInfo(id: number) {
  const { data } = await axios.delete(`${APIs.DRIVERS}/bank-info/${id}`);
  return data;
}

async function fetchOrders() {
  const { data } = await axios.get(APIs.ORDERS);
  return data;
}

async function fetchOrdersPagination(params: any) {
  const { data } = await axios.get(`${APIs.ORDERS}/pagination`, {
    params,
  });
  return data;
}

async function fetchOrderById(orderId: string) {
  const { data } = await axios.get(APIs.ORDERS, {
    params: {
      order_id: orderId,
    },
  });
  return data;
}

async function fetchMatchingOrders() {
  const { data } = await axios.get(`${APIs.ORDERS}/matching`);
  return data;
}

async function addOrder(values: any) {
  const { data } = await axios.post(APIs.ORDERS, values);
  return data;
}

async function updateOrder(values: any) {
  const { data } = await axios.put(APIs.ORDERS, values);
  return data;
}

async function removeOrder(id: number) {
  const { data } = await axios.delete(`${APIs.ORDERS}/${id}`);
  return data;
}

async function fetchCustomers() {
  const { data } = await axios.get(APIs.CUSTOMERS, {
    params: {
      info: true,
    },
  });
  return data;
}

async function fetchCustomerPagination(params: any) {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/pagination`, {
    params,
  });
  return data;
}

async function fetchCustomerAssign() {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/customer-assign`);
  return data;
}

async function removeUserFromCustomerAssign(id: number) {
  const { data } = await axios.delete(`${APIs.CUSTOMERS}/customer-assign/${id}`);
  return data;
}

async function assignCustomerToUser(userId: number, ids: number[]) {
  const { data } = await axios.put(`${APIs.CUSTOMERS}/customer-assign`, {
    userId,
    ids,
  });
  return data;
}

async function fetchTakeCareDetails(id: number) {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/contacted`, {
    params: { id },
  });
  return data;
}

async function autoAssignCustomers(userIds: number[]) {
  const { data } = await axios.post(`${APIs.CUSTOMERS}/auto-assign`, {
    userIds,
  });
  return data;
}

async function fetchAssignedCustomers(date: string) {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/assigned-customers`, {
    params: {
      date,
    },
  });
  return data;
}

async function moveCustomerAssign(payload: any) {
  const { data } = await axios.put(`${APIs.CUSTOMERS}/customer-assign/move`, payload);
  return data;
}

async function moveCtvAssignments(payload: any) {
  const { data } = await axios.put(`${APIs.CUSTOMERS}/ctv-assign/move`, payload);
  return data;
}

async function fetchCustomerById(id: number) {
  const { data } = await axios.get(APIs.CUSTOMERS, {
    params: {
      id: id,
    },
  });
  return data;
}

async function addCustomer(values: any) {
  const { data } = await axios.post(APIs.CUSTOMERS, values);
  return data;
}

async function addChildCustomer(values: any) {
  const { data } = await axios.post(`${APIs.CUSTOMERS}/child-customer`, values);
  return data;
}

async function updateCustomer(values: any) {
  const { data } = await axios.put(APIs.CUSTOMERS, values);
  return data;
}

async function updateChildCustomer(values: any) {
  const { data } = await axios.put(`${APIs.CUSTOMERS}/child-customer`, values);
  return data;
}

async function removeCustomer(id: number) {
  const { data } = await axios.delete(`${APIs.CUSTOMERS}/${id}`);
  return data;
}

async function findCustomerByCustomerID(customerID: string) {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/find`, {
    params: { customer_id: customerID },
  });
  return data;
}

async function fetchCtvIDs() {
  const { data } = await axios.get(`${APIs.CUSTOMERS}/ctvs`);
  return data;
}

async function getTransportInfo(id: number) {
  const { data } = await axios.get(`${APIs.TRANSPORTS}/info/${id}`);
  return data;
}

async function uploadTransportInfo(id: any, ownerId: string, key: string, files: any, onUploadProgress: any) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append(`file${i}`, files[i]);
  }
  formData.append('key', key);
  formData.append('id', id);
  formData.append('ownerId', ownerId);

  return axios.post(`${APIs.TRANSPORTS}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function removeTransportInfo(id: any, ownerId: any, file: string) {
  const { data } = await axios.put(`${APIs.TRANSPORTS}/upload`, {
    id,
    ownerId,
    file,
  });
  return data;
}

async function fetchOwners(owner_id: string = '') {
  const params = owner_id === '' ? {} : { owner_id: owner_id };

  const { data } = await axios.get(APIs.OWNERS, {
    params,
  });
  return data;
}

async function fetchOwnersPagination(params: any) {
  const { data } = await axios.get(`${APIs.OWNERS}/pagination`, {
    params,
  });
  return data;
}

async function fetchOwnerById(ownerId: string) {
  const { data } = await axios.get(APIs.OWNERS, {
    params: {
      owner_id: ownerId,
    },
  });
  return data;
}

async function fetchOwnerIds() {
  const { data } = await axios.get(`${APIs.OWNERS}/owner-ids`);
  return data;
}

async function fetchInstantOwner(ownerId: string) {
  const { data } = await axios.get(`${APIs.OWNERS}/instant`, {
    params: {
      owner_id: ownerId,
    },
  });
  return data;
}

async function fetchInstantOwners() {
  const { data } = await axios.get(`${APIs.OWNERS}/instants_2`);
  return data;
}

async function fetchV2InstantOwners(isFull: boolean = true) {
  const { data } = await axios.get(APIs.INSTANTS, {
    params: {
      is_full: isFull,
    },
  });
  return data;
}

async function fetchV2InstantMatching(ownerIDs: string[]) {
  const { data } = await axios.get(`${APIs.INSTANTS}/matching`, {
    params: {
      ownerIDs,
    },
  });
  return data;
}

async function updateMatching(values: any) {
  const { data } = await axios.put(`${APIs.INSTANTS}/matching`, values);
  return data;
}

async function reserveMatching(values: any) {
  const { data } = await axios.put(`${APIs.INSTANTS}/reserve`, values);
  return data;
}

async function getDriverSuggestion(ownerID: string) {
  const { data } = await axios.get(`${APIs.OWNERS}/driver-suggestion`, {
    params: {
      owner_id: ownerID,
    },
  });
  return data;
}

async function getDriverSuggestionDetail(vehicleID: string) {
  const { data } = await axios.get(`${APIs.OWNERS}/driver-suggestion-detail`, {
    params: {
      vehicle_id: vehicleID,
    },
  });
  return data;
}

async function updateContactDriverSuggestion(vehicleID: number, ownerID: string) {
  const { data } = await axios.put(`${APIs.OWNERS}/driver-suggestion`, {
    vehicle_id: vehicleID,
    owner_id: ownerID,
  });
  return data;
}

async function reserveInstantOwner(ownerID: string) {
  const { data } = await axios.post(`${APIs.OWNERS}/instant/reserve`, {
    owner_id: ownerID,
  });
  return data;
}

async function removeInstantOwner(id: number) {
  const { data } = await axios.delete(`${APIs.OWNERS}/instant/${id}`);
  return data;
}

async function addOwner(values: any) {
  const { data } = await axios.post(APIs.OWNERS, values);
  return data;
}

async function updateOwner(values: any) {
  const { data } = await axios.put(APIs.OWNERS, values);
  return data;
}

async function removeOwner(id: number) {
  const { data } = await axios.delete(`${APIs.OWNERS}/${id}`);
  return data;
}

async function createPriceQuote(payload: any) {
  const { data } = await axios.post(`${APIs.OWNERS}/price-quote`, payload);
  return data;
}

async function getPendingOwners(params: any) {
  const { data } = await axios.get(`${APIs.OWNERS}/ctv-owners`, {
    params,
  });
  return data;
}

async function getPendingOwnerCount() {
  const { data } = await axios.get(`${APIs.OWNERS}/ctv-owners/count`);
  return data;
}

async function approveRejectCtvOwner(payload: any) {
  const { data } = await axios.put(`${APIs.OWNERS}/ctv-owners/approval`, payload);
  return data;
}

async function updateCtvOwner(payload: any) {
  const { data } = await axios.put(`${APIs.OWNERS}/ctv-owners`, payload);
  return data;
}

async function fetchTransports() {
  const { data } = await axios.get(APIs.TRANSPORTS);
  return data;
}

async function fetchTransportsPagination(params: any) {
  const { data } = await axios.get(`${APIs.TRANSPORTS}/pagination`, {
    params,
  });
  return data;
}

async function fetchTransportByOwnerId(ownerId: string) {
  const { data } = await axios.get(APIs.TRANSPORTS, {
    params: {
      owner_id: ownerId,
    },
  });
  return data;
}

async function addTransport(values: any) {
  const { data } = await axios.post(APIs.TRANSPORTS, values);
  return data;
}

async function updateTransport(values: any) {
  const { data } = await axios.put(APIs.TRANSPORTS, values);
  return data;
}

async function removeTransport(id: number) {
  const { data } = await axios.delete(`${APIs.TRANSPORTS}/${id}`);
  return data;
}

async function getDriverInfo(id: number) {
  const { data } = await axios.get(`${APIs.DRIVERS}/info/${id}`);
  return data;
}

async function uploadDriverInfo(vehicleId: any, driverId: string, key: string, files: any, onUploadProgress: any) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append(`file${i}`, files[i]);
  }
  formData.append('key', key);
  formData.append('id', vehicleId);
  formData.append('driverId', driverId);

  return axios.post(`${APIs.DRIVERS}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function removeDriverInfo(vehicleId: any, driverId: string, file: string) {
  const { data } = await axios.put(`${APIs.DRIVERS}/upload`, {
    vehicleId,
    driverId,
    file,
  });
  return data;
}

async function shareDriverInfo(groupIDs: number[], files: string[]) {
  return axios.post(`${APIs.DRIVERS}/share-info`, {
    groupIDs,
    files,
  });
}

// Chanh
async function fetchChanhs() {
  const { data } = await axios.get(APIs.CHANHS);
  return data;
}

async function fetchChanhByChanhId(chanhId: string) {
  const { data } = await axios.get(APIs.CHANHS, {
    params: {
      chanh_id: chanhId,
    },
  });
  return data;
}

async function addChanh(values: any) {
  const { data } = await axios.post(APIs.CHANHS, values);
  return data;
}

async function updateChanh(values: any) {
  const { data } = await axios.put(APIs.CHANHS, values);
  return data;
}

async function removeChanh(id: number) {
  const { data } = await axios.delete(`${APIs.CHANHS}/${id}`);
  return data;
}

// Chanh DH
async function fetchChanhDHs() {
  const { data } = await axios.get(APIs.CHANH_DHS);
  return data;
}

async function fetchChanhDHById(chanhDHId: string) {
  const { data } = await axios.get(APIs.CHANH_DHS, {
    params: {
      chanh_dh_id: chanhDHId,
    },
  });
  return data;
}

async function addChanhDH(values: any) {
  const { data } = await axios.post(APIs.CHANH_DHS, values);
  return data;
}

async function updateChanhDH(values: any) {
  const { data } = await axios.put(APIs.CHANH_DHS, values);
  return data;
}

async function removeChanhDH(id: number) {
  const { data } = await axios.delete(`${APIs.CHANH_DHS}/${id}`);
  return data;
}

async function fetchReceipt(ownerId: string) {
  const { data } = await axios.get(`${APIs.OWNERS}/receipt`, {
    params: {
      owner_id: ownerId,
    },
  });
  return data;
}

async function updateReceipt(ownerId: string, receipt: string) {
  const { data } = await axios.put(`${APIs.OWNERS}/receipt`, {
    owner_id: ownerId,
    receipt,
  });
  return data;
}

async function uploadReceipt(ownerId: string, name: string, file: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('ownerId', ownerId);
  formData.append('name', name);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const { data } = await axios.post(`${APIs.OWNERS}/upload`, formData, config);
  return data;
}

async function fetchWorkingTime(from: any, to: any) {
  const { data } = await axios.get(APIs.WORKING_TIME, {
    params: {
      fromTime: from,
      toTime: to,
    },
  });
  return data;
}

async function fetchRequestOff(from: any, to: any) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/off`, {
    params: {
      fromTime: from,
      toTime: to,
    },
  });
  return data;
}

async function addWorkingTime(payload: any) {
  const { data } = await axios.post(APIs.WORKING_TIME, payload);
  return data;
}

async function updateWorkingTime(payload: any) {
  const { data } = await axios.put(APIs.WORKING_TIME, payload);
  return data;
}

async function removeWorkingTime(id: string) {
  const { data } = await axios.delete(`${APIs.WORKING_TIME}/${id}`);
  return data;
}

async function fetchAllWorkingTime(from: any, to: any) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/all`, {
    params: {
      fromTime: from,
      toTime: to,
    },
  });
  return data;
}

async function approveRejectWorkingTime(id: string, status: string) {
  const { data } = await axios.post(`${APIs.WORKING_TIME}/verify`, { id, status });
  return data;
}

async function fetchPendingApproveWorkingTime() {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/pending`);
  return data;
}

async function getWorkingUsers() {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/users`);
  return data;
}

async function getUserSalaries(type: string, from: any, to: any) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/salaries`, {
    params: {
      type,
      from,
      to,
    },
  });
  return data;
}

async function fetchUserKPI(from: any, to: any, status: string) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/kpi`, {
    params: {
      from,
      to,
      status,
    },
  });
  return data;
}

async function updateUserSalaries(payload: any) {
  const { data } = await axios.put(`${APIs.WORKING_TIME}/salaries`, payload);
  return data;
}

async function fetchTransportPayment(owner_id: string) {
  const { data } = await axios.get(`${APIs.TRANSPORTS}/payment`, {
    params: {
      owner_id,
    },
  });
  return data;
}

async function updatePayment(payload: any) {
  const { data } = await axios.post(`${APIs.TRANSPORTS}/payment`, payload);
  return data;
}

async function fetchPendingPayments() {
  const { data } = await axios.get(`${APIs.TRANSPORTS}/payments`);
  return data;
}

async function fetchWorkingStatistic(userId: number, from: number, to: number) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/statistic`, {
    params: {
      userId,
      from,
      to,
    },
  });
  return data;
}

async function fetchCallTrackingStatistic(userId: number, from: number, to: number) {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/call-tracking`, {
    params: {
      userId,
      from,
      to,
    },
  });
  return data;
}

async function getContacts() {
  const { data } = await axios.get(APIs.CONTACTS);
  return data;
}

async function addContact(values: any) {
  const { data } = await axios.post(APIs.CONTACTS, values);
  return data;
}

async function updateContact(values: any) {
  const { data } = await axios.put(APIs.CONTACTS, values);
  return data;
}

async function removeContact(id: number) {
  const { data } = await axios.delete(`${APIs.CONTACTS}/${id}`);
  return data;
}

async function fetchInsurances(includeExpired?: boolean, includeArchived?: boolean) {
  const { data } = await axios.get(APIs.INSURANCES, {
    params: {
      includeExpired: includeExpired,
      includeArchived: includeArchived,
    },
  });
  return data;
}

async function getSettings(key: string) {
  const { data } = await axios.get(APIs.SETTINGS, {
    params: {
      key,
    },
  });
  return data;
}

async function fetchInsuranceByInsuranceId(insuranceId: string) {
  const { data } = await axios.get(APIs.INSURANCES, {
    params: {
      insurance_id: insuranceId,
    },
  });
  return data;
}

async function addInsurance(values: any) {
  const { data } = await axios.post(APIs.INSURANCES, values);
  return data;
}

async function updateInsurance(values: any) {
  const { data } = await axios.put(APIs.INSURANCES, values);
  return data;
}

async function removeInsurance(id: number) {
  const { data } = await axios.delete(`${APIs.INSURANCES}/${id}`);
  return data;
}

async function autoAssignInsurance(userIds: number[]) {
  const { data } = await axios.put(`${APIs.INSURANCES}/auto-assign`, {
    userIds,
  });
  return data;
}

async function fetchOwnerInsurances() {
  const { data } = await axios.get(APIs.OWNER_INSURANCES);
  return data;
}

async function addOwnerInsurance(values: any) {
  const { data } = await axios.post(APIs.OWNER_INSURANCES, values);
  return data;
}

async function updateOwnerInsurance(values: any) {
  const { data } = await axios.put(APIs.OWNER_INSURANCES, values);
  return data;
}

async function updateOwnerInsuranceStatus(values: any) {
  const { data } = await axios.put(`${APIs.OWNER_INSURANCES}/status`, values);
  return data;
}

async function removeOwnerInsurance(id: number) {
  const { data } = await axios.delete(`${APIs.OWNER_INSURANCES}/${id}`);
  return data;
}

async function getGeneralSettingSalaries() {
  const { data } = await axios.get(`${APIs.WORKING_TIME}/general-settings`);
  return data;
}

async function updateGeneralSettingSalary(values: any) {
  const { data } = await axios.put(`${APIs.WORKING_TIME}/general-settings`, values);
  return data;
}

async function fetchDriverRelationship(id: number) {
  const { data } = await axios.get(`${APIs.DRIVERS}/relationship/${id}`);
  return data;
}

async function fetchInteractApprovals(showAll = false) {
  const { data } = await axios.get(`${APIs.DRIVERS}/interact-approvals`, {
    params: {
      showAll,
    },
  });
  return data;
}

async function updateInteractApprovals(payload: any) {
  const { data } = await axios.put(`${APIs.DRIVERS}/interact-approvals`, payload);
  return data;
}

async function fetchCities() {
  const { data } = await axios.get(`${APIs.OWNERS}/cities`);
  return data;
}

async function fetchDistricts() {
  const { data } = await axios.get(`${APIs.OWNERS}/districts`);
  return data;
}

async function fetchChatGroups(
  page: number,
  keyword: string,
  chatType: string,
  driverChatType: string,
  isChatOnly: boolean,
) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups`, {
    params: {
      page: page,
      itemsPerPage: 10,
      keyword: keyword,
      chatType: chatType,
      driverChatType: driverChatType,
      isChatOnly: isChatOnly,
    },
  });
  return data;
}

async function saveChatMessages(messages: any[]) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/messages`, messages);
  return data;
}

async function fetchChatUsersInGroup(id: number) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/users/${id}`);
  return data;
}

async function fetchChatGroupByOwnerID(ownerID: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/owner`, {
    params: {
      ownerID: ownerID,
    },
  });
  return data;
}

async function joinOwnerChatGroup(groupID: number) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/join-owner-chat`, {
    groupID,
  });
  return data;
}

async function fetchChatGroupsManagement(
  page: number,
  itemsPerPage: number,
  keyword: string,
  chatType: string,
  totalRecords: number,
) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/manage-groups`, {
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      keyword: keyword,
      chatType: chatType,
      totalRecords: totalRecords,
    },
  });
  return data;
}

async function updateInternalChatGroups(payload: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/restrict-groups`, payload);
  return data;
}

async function removeInternalGroupsRestriction(id: number) {
  const { data } = await axios.delete(`${config.chatServerUrl}${APIs.WEB_CHAT}/restrict-groups/${id}`);
  return data;
}

async function removeChatGroup(id: number) {
  const { data } = await axios.delete(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/${id}`);
  return data;
}

async function searchChatGroups(keyword: string, page: number, chatType: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/search`, {
    params: {
      keyword: keyword,
      chatType: chatType,
      page: page,
      itemsPerPage: 50,
    },
  });
  return data;
}

async function fetchGroupRoles(chatType: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups-roles`, {
    params: {
      chatType: chatType,
    },
  });
  return data;
}

async function updateInternalChatGroupsRoles(payload: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups-roles`, payload);
  return data;
}

async function fetchStaffCrossChat() {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/staff-cross-chat`);
  return data;
}

async function updateStaffCrossChat(payload: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/staff-cross-chat`, payload);
  return data;
}

async function fetchChatMessages(groupID: number, page: number) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/messages/${groupID}`, {
    params: {
      page: page,
      itemsPerPage: 20,
    },
  });
  return data;
}

async function fetchChatMessagesByToken(groupID: number, prevToken: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/v2/messages/${groupID}`, {
    params: {
      prevToken: prevToken,
      itemsPerPage: 50,
    },
  });
  return data;
}

async function searchChatMessages(groupID: any, keyword: string, page: number) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/messages/search`, {
    params: {
      groupId: groupID,
      keyword: keyword,
      page: page,
      itemsPerPage: 50,
    },
  });
  return data;
}

async function saveChatMessageReaction(id: number, reaction: string) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/messages/react`, {
    id,
    reaction,
  });
  return data;
}

async function searchChatMembers(text: string) {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/search`, {
    params: {
      text: text,
    },
  });
  return data;
}

async function addChatMember(payload: any) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/add-member`, payload);
  return data;
}

async function removeChatMember(payload: any) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/remove-member`, payload);
  return data;
}

async function updateChatGroup(payload: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups`, payload);
  return data;
}

async function createCommonDriverChat(id: number) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/common-driver-chat`, {
    id,
  });
  return data;
}

async function updateLastSeen(groupID: any) {
  const { data } = await axios.put(`${config.chatServerUrl}${APIs.WEB_CHAT}/groups/seen/${groupID}`);
  return data;
}

async function fetchUnreadMessageCount() {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/unread-by-group`);
  return data;
}

async function forwardMessage(payload: any) {
  const { data } = await axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/forward`, payload);
  return data;
}

// Regulations
async function fetchRegulations(type: string) {
  const { data } = await axios.get(`${APIs.REGULATIONS}`, {
    params: {
      type,
    },
  });
  return data;
}

async function addRegulation(payload: any) {
  const { data } = await axios.post(`${APIs.REGULATIONS}`, payload);
  return data;
}

async function updateRegulation(payload: any) {
  const { data } = await axios.put(`${APIs.REGULATIONS}`, payload);
  return data;
}

async function removeRegulation(id: number) {
  const { data } = await axios.delete(`${APIs.REGULATIONS}/${id}`);
  return data;
}

// Regulation Violation
async function addRegulationViolation(payload: any) {
  const { data } = await axios.post(`${APIs.REGULATION_VIOLATIONS}`, payload);
  return data;
}

async function fetchRegulationViolations(type?: string) {
  const { data } = await axios.get(`${APIs.REGULATION_VIOLATIONS}`, {
    params: {
      type,
    },
  });
  return data;
}

async function updateRegulationViolationStatus(values: any) {
  const { data } = await axios.put(`${APIs.REGULATION_VIOLATIONS}/${values.id}/status`, values);
  return data;
}

async function removeRegulationViolation(id: number) {
  const { data } = await axios.delete(`${APIs.REGULATION_VIOLATIONS}/${id}`);
  return data;
}

// Button
async function fetchButtons() {
  const { data } = await axios.get(`${APIs.BUTTONS}`);
  return data;
}

async function uploadChatFile(
  groupId: string,
  messageID: string,
  replyID: string,
  type: string,
  file: any,
  onUploadProgress: any,
) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('group_id', groupId);
  formData.append('message_id', messageID);
  formData.append('reply_id', replyID);
  formData.append('type', type);
  return axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function uploadChatFileV2(
  groupId: string,
  messageID: string,
  replyID: string,
  type: string,
  files: any[],
  onUploadProgress: any,
) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
  formData.append('group_id', groupId);
  formData.append('message_id', messageID);
  formData.append('reply_id', replyID);
  formData.append('type', type);
  return axios.post(`${config.chatServerUrl}${APIs.WEB_CHAT}/v2/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function fetchLatestChat() {
  const { data } = await axios.get(`${config.chatServerUrl}${APIs.WEB_CHAT}/latest-chat`);
  return data;
}

async function uploadFileManagement(file: any, name: any, parent: any, is_file: any, onUploadProgress: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  if (parent) {
    formData.append('parent', parent);
  }
  formData.append('is_file', is_file);
  return axios.post(`${APIs.FILES}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function fetchFiles(user_id?: any, parent?: any) {
  const { data } = await axios.get(`${APIs.FILES}`, {
    params: {
      parent: parent,
      user_id: user_id,
    },
  });
  return data;
}

async function updateFileManagement(file: any, id: any, name: any, parent: any, is_file: any, onUploadProgress: any) {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('file', file);
  formData.append('name', name);
  formData.append('parent', parent);
  formData.append('is_file', is_file);
  return axios.put(`${APIs.FILES}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

async function removeFileManagement(fileID: number) {
  const { data } = await axios.delete(`${APIs.FILES}/${fileID}`);
  return data;
}

// Start ke toan
async function fetchFinancial(accountSource?: string, from?: number, to?: number) {
  const { data } = await axios.get(APIs.FINANCIAL, {
    params: {
      account_source: accountSource,
      from,
      to,
    },
  });
  return data;
}

async function addFinancial(values: any) {
  const { data } = await axios.post(APIs.FINANCIAL, values);
  return data;
}

async function updateFinancial(values: any) {
  const { data } = await axios.put(`${APIs.FINANCIAL}`, values);
  return data;
}

async function updateFinancialStatus(values: any) {
  const { data } = await axios.put(`${APIs.FINANCIAL}/${values.id}/status`, values);
  return data;
}

async function removeFinancial(id: number) {
  const { data } = await axios.delete(`${APIs.FINANCIAL}/${id}`);
  return data;
}
// End ke toan

async function getArchivedDrivers() {
  const { data } = await axios.get(`${APIs.DRIVERS}/archives`);
  return data;
}

async function archiveDriver(payload: any) {
  const { data } = await axios.post(`${APIs.DRIVERS}/archives`, payload);
  return data;
}

async function removeArchivedDriver(id: number) {
  const { data } = await axios.delete(`${APIs.DRIVERS}/archives/${id}`);
  return data;
}

async function fetchOwnerAssignments() {
  const { data } = await axios.get(`${APIs.OWNER_ASSIGNMENTS}`);
  return data;
}

async function saveOwnerAssignments(payload: any) {
  const { data } = await axios.post(`${APIs.OWNER_ASSIGNMENTS}`, payload);
  return data;
}

async function fetchAssignedOwners() {
  const { data } = await axios.get(`${APIs.OWNER_ASSIGNMENTS}/assigned-owners`);
  return data;
}

async function assignOwners() {
  const { data } = await axios.post(`${APIs.OWNER_ASSIGNMENTS}/assign-owners`);
  return data;
}

async function fetchPriceQuotes(params: any) {
  const { data } = await axios.get(`${APIs.PRICE_QUOTES}/pagination`, {
    params,
  });
  return data;
}

async function getManagerSetting() {
  const { data } = await axios.get(`${APIs.MANAGER_SETTINGS}`);
  return data;
}

async function updateManagerSettings(payload: any) {
  const { data } = await axios.put(`${APIs.MANAGER_SETTINGS}`, payload);
  return data;
}

async function updateManagerRoles(payload: any) {
  const { data } = await axios.put(`${APIs.MANAGER_SETTINGS}/roles`, payload);
  return data;
}

async function fetchManagerRoles() {
  const { data } = await axios.get(`${APIs.MANAGER_SETTINGS}/roles`);
  return data;
}

async function fetchCustomerPayments(params: any) {
  const { data } = await axios.get(`${APIs.PAYMENTS}/customer-payments`, {
    params,
  });
  return data;
}

async function fetchCustomerPaymentByID(customerPaymentID: number) {
  const { data } = await axios.get(`${APIs.PAYMENTS}/customer-payments/${customerPaymentID}`);
  return data;
}

async function saveCustomerPayment(payload: any) {
  const { data } = await axios.post(`${APIs.PAYMENTS}/customer-payments`, payload);
  return data;
}

async function saveCustomerOwnerPayment(payload: any) {
  const { data } = await axios.post(`${APIs.PAYMENTS}/customer-owner-payments`, payload);
  return data;
}

async function fetchCustomerOwnerPayments(params: any) {
  const { data } = await axios.get(`${APIs.PAYMENTS}/customer-owner-payments`, {
    params,
  });
  return data;
}

async function approveCustomerOwnerPayment(customerOwnerPaymentID: number) {
  const { data } = await axios.put(`${APIs.PAYMENTS}/customer-owner-payments/${customerOwnerPaymentID}/approve`);
  return data;
}

async function rejectCustomerOwnerPayment(customerOwnerPaymentID: number) {
  const { data } = await axios.put(`${APIs.PAYMENTS}/customer-owner-payments/${customerOwnerPaymentID}/reject`);
  return data;
}

async function getCustomerPaymentByOwner(ownerID: string) {
  const { data } = await axios.get(`${APIs.PAYMENTS}/owners/${ownerID}`);
  return data;
}

async function cancelCustomerPayment(customerPaymentID: number) {
  const { data } = await axios.delete(`${APIs.PAYMENTS}/customer-payments/${customerPaymentID}`);
  return data;
}

async function cancelCustomerOwnerPayment(customerOwnerPaymentID: number) {
  const { data } = await axios.delete(`${APIs.PAYMENTS}/customer-owner-payments/${customerOwnerPaymentID}`);
  return data;
}

async function fetchKhBanks() {
  const { data } = await axios.get(`${APIs.OWNERS}/kh-banks`);
  return data;
}

async function fetchKyQuy(params: any) {
  const { data } = await axios.get(`${APIs.OWNERS}/ky-quy`, {
    params,
  });
  return data;
}

async function fetchKyQuyDetail(createdBy: number, customerId: number) {
  const { data } = await axios.get(`${APIs.OWNERS}/chi-tiet-ky-quy`, {
    params: {
      created_by: createdBy,
      customer_f_id: customerId,
    },
  });
  return data;
}

async function addTrichQuyRequest(values: any) {
  const { data } = await axios.post(`${APIs.OWNERS}/trich-quy`, values);
  return data;
}

async function approveTrichQuyRequest(values: any) {
  const { data } = await axios.put(`${APIs.OWNERS}/trich-quy`, values);
  return data;
}

async function fetchTraSoatDonHang(params: any) {
  const { data } = await axios.get(`${APIs.OWNERS}/tra-soat`, {
    params,
  });
  return data;
}

async function updateTraSoatDonHang(values: any) {
  const { data } = await axios.put(`${APIs.OWNERS}/tra-soat`, values);
  return data;
}

const APIService = {
  fetchDrivers,
  fetchDriversPagination,
  fetchDriverByDriverId,
  updateNearingExpirationDay,
  updateTakeCareClient,
  fetchInsuranceHistory,
  addDriver,
  updateDriver,
  updateDriverInteractive,
  fetchVehiclesByDriverID,
  addVehicle,
  updateVehicle,
  addLicensePlate,
  updateLicensePlate,
  removeDriver,
  findDriverByDriverID,
  addDriverBankInfo,
  updateDriverBankInfo,
  removeDriverBankInfo,
  fetchOrders,
  fetchOrdersPagination,
  fetchOrderById,
  fetchMatchingOrders,
  addOrder,
  updateOrder,
  removeOrder,
  fetchDriverAssign,
  assignDriverToUser,
  autoAssignDrivers,
  changeDriverAssignSupporter,
  moveDriverAssign,
  removeUserFromDriverAssign,
  fetchChatManagers,
  updateChatManagers,
  // Customer
  fetchCustomers,
  fetchCustomerPagination,
  fetchCustomerById,
  fetchCustomerAssign,
  removeUserFromCustomerAssign,
  assignCustomerToUser,
  fetchTakeCareDetails,
  autoAssignCustomers,
  fetchAssignedCustomers,
  moveCustomerAssign,
  moveCtvAssignments,
  addCustomer,
  addChildCustomer,
  updateCustomer,
  updateChildCustomer,
  removeCustomer,
  findCustomerByCustomerID,
  fetchCtvIDs,
  getTransportInfo,
  uploadTransportInfo,
  removeTransportInfo,
  // Owner
  fetchOwners,
  fetchOwnersPagination,
  fetchOwnerById,
  fetchOwnerIds,
  fetchInstantOwner,
  fetchInstantOwners,
  fetchV2InstantOwners,
  fetchV2InstantMatching,
  updateMatching,
  reserveMatching,
  getDriverSuggestion,
  getDriverSuggestionDetail,
  updateContactDriverSuggestion,
  reserveInstantOwner,
  removeInstantOwner,
  addOwner,
  updateOwner,
  removeOwner,
  fetchTransports,
  fetchTransportsPagination,
  fetchTransportByOwnerId,
  addTransport,
  updateTransport,
  removeTransport,
  getDriverInfo,
  uploadDriverInfo,
  removeDriverInfo,
  shareDriverInfo,

  fetchReceipt,
  updateReceipt,
  fetchChanhs,
  fetchChanhByChanhId,
  addChanh,
  updateChanh,
  removeChanh,
  fetchChanhDHs,
  fetchChanhDHById,
  addChanhDH,
  updateChanhDH,
  removeChanhDH,
  uploadReceipt,
  fetchWorkingTime,
  fetchRequestOff,
  addWorkingTime,
  updateWorkingTime,
  removeWorkingTime,
  fetchAllWorkingTime,
  approveRejectWorkingTime,
  fetchPendingApproveWorkingTime,
  getWorkingUsers,
  getUserSalaries,
  fetchUserKPI,
  updateUserSalaries,
  fetchTransportPayment,
  updatePayment,
  fetchPendingPayments,
  fetchWorkingStatistic,
  fetchCallTrackingStatistic,
  getContacts,
  addContact,
  updateContact,
  removeContact,
  fetchInsurances,
  getSettings,
  fetchInsuranceByInsuranceId,
  addInsurance,
  updateInsurance,
  removeInsurance,
  autoAssignInsurance,
  fetchOwnerInsurances,
  addOwnerInsurance,
  updateOwnerInsurance,
  updateOwnerInsuranceStatus,
  removeOwnerInsurance,
  getGeneralSettingSalaries,
  updateGeneralSettingSalary,
  fetchDriverRelationship,
  fetchInteractApprovals,
  updateInteractApprovals,
  fetchCities,
  fetchDistricts,
  fetchChatGroups,
  fetchChatUsersInGroup,
  fetchChatGroupByOwnerID,
  joinOwnerChatGroup,
  saveChatMessages,
  fetchChatGroupsManagement,
  updateInternalChatGroups,
  removeInternalGroupsRestriction,
  removeChatGroup,
  fetchGroupRoles,
  searchChatGroups,
  updateInternalChatGroupsRoles,
  fetchStaffCrossChat,
  updateStaffCrossChat,
  fetchChatMessages,
  fetchChatMessagesByToken,
  searchChatMessages,
  saveChatMessageReaction,
  searchChatMembers,
  addChatMember,
  removeChatMember,
  updateChatGroup,
  createCommonDriverChat,
  updateLastSeen,
  fetchUnreadMessageCount,
  forwardMessage,
  uploadChatFile,
  uploadChatFileV2,
  fetchLatestChat,
  fetchRegulations,
  addRegulation,
  updateRegulation,
  removeRegulation,
  fetchButtons,
  createPriceQuote,
  getPendingOwners,
  getPendingOwnerCount,
  updateCtvOwner,
  approveRejectCtvOwner,
  // Regulation Violation
  addRegulationViolation,
  fetchRegulationViolations,
  updateRegulationViolationStatus,
  removeRegulationViolation,

  // Archived
  getArchivedDrivers,
  archiveDriver,
  removeArchivedDriver,

  // FileManagement
  uploadFileManagement,
  fetchFiles,
  updateFileManagement,
  removeFileManagement,

  // Ke toan Management
  fetchFinancial,
  addFinancial,
  updateFinancial,
  updateFinancialStatus,
  removeFinancial,

  // owner assignments
  fetchOwnerAssignments,
  saveOwnerAssignments,
  fetchAssignedOwners,
  assignOwners,

  // price quotes
  fetchPriceQuotes,

  // manager settings
  getManagerSetting,
  updateManagerSettings,
  fetchManagerRoles,
  updateManagerRoles,

  // customer payments
  fetchCustomerPayments,
  fetchCustomerPaymentByID,
  saveCustomerPayment,
  saveCustomerOwnerPayment,
  fetchCustomerOwnerPayments,
  approveCustomerOwnerPayment,
  rejectCustomerOwnerPayment,
  getCustomerPaymentByOwner,
  cancelCustomerPayment,
  cancelCustomerOwnerPayment,

  fetchKhBanks,

  // Ky quy
  fetchKyQuy,
  fetchKyQuyDetail,
  addTrichQuyRequest,
  approveTrichQuyRequest,

  // Tra soat
  fetchTraSoatDonHang,
  updateTraSoatDonHang,
};

export default APIService;

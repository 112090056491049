import { useSelector, useDispatch } from 'react-redux';
import {
  userSelector,
  selectedGCSelector,
  unreadMessageCountSelector,
  chatGroupsSelector,
  newChatMessageSelector,
  selectedChatSelector,
  instantsSelector,
  instantSuggestionsSelector,
  ownerAssignmentsSelector,
  matchingOrderSelector,
  matchingOwnerSelector,
  updateChatMessageSelector,
  newOrderSelector,
  instantOwnerIDSelector,
  newPriceQuotesSelector,
  instantV2Selector,
  instantReserveSelector,
} from '../selectors';
import { setSelectedGC } from '../apps/actions';
import { setChatGroups } from '../user/actions';

export function useUser() {
  return useSelector(userSelector);
}

export function useSelectedGC() {
  const dispatch = useDispatch();
  return {
    selectedGC: useSelector(selectedGCSelector),
    setSelectedGC: (selectedGC: any | undefined) => dispatch(setSelectedGC(selectedGC)),
  };
}

export function useUnreadMessageCount() {
  return useSelector(unreadMessageCountSelector);
}

export function useChatGroups() {
  const dispatch = useDispatch();
  return {
    chatGroups: useSelector(chatGroupsSelector),
    setChatGroups: (chatGroups: any[]) => dispatch(setChatGroups(chatGroups)),
  };
}

export function useNewChatMessage() {
  return useSelector(newChatMessageSelector);
}

export function useUpdateChatMessage() {
  return useSelector(updateChatMessageSelector);
}

export function useSelectedChat() {
  return useSelector(selectedChatSelector);
}

export function useInstants() {
  return useSelector(instantsSelector);
}

export function useV2Instants() {
  return useSelector(instantV2Selector);
}

export function useInstantOwnerID() {
  return useSelector(instantOwnerIDSelector);
}

export function useNewOrder() {
  return useSelector(newOrderSelector);
}

export function useNewPriceQuotes() {
  return useSelector(newPriceQuotesSelector);
}

export function useInstantReserve() {
  return useSelector(instantReserveSelector);
}

export function useInstantSuggestions() {
  return useSelector(instantSuggestionsSelector);
}

export function useOwnerAssignments() {
  return useSelector(ownerAssignmentsSelector);
}

export function useMatchingOrder() {
  return useSelector(matchingOrderSelector);
}

export function useMatchingOwner() {
  return useSelector(matchingOwnerSelector);
}
